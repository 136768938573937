import React from "react";
import "./Technology.css";

const Technology = ({ name, url, logo }) => {
  const { url: logoUrl } = logo.file;
  return (
    <div className="col-lg-2 margin-t-20">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className="services-box text-center hover-effect">
          <img className="technologyLogo" src={logoUrl} alt={name} />
          <p className="section-subtitle text-muted text-center padding-t-30 font-secondary">
            {name}
          </p>
        </div>
      </a>
    </div>
  );
};

export default Technology;
