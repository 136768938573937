import React from "react";
import "./TeamMember.css";

const TeamMember = ({ name, description, photo }) => {
  const { url: photoUrl, slug } = photo.file;
  return (
    <div key={slug} className="col-lg-3 col-sm-6">
      <div className="team-box text-center hover-effect">
        <div className="team-wrapper">
          <div className="team-member">
            <img alt={name} src={photoUrl} className="img-fluid rounded" />
          </div>
        </div>
        <h4 className="team-name">{name}</h4>
        <p className="text-uppercase team-designation">{description}</p>
      </div>
    </div>
  );
};

export default TeamMember;
