import React from "react";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "./Service.css";

const Text = ({ children }) => (
  <p className="padding-t-15 text-muted">{children}</p>
);

const renderParagraph = (node, children) => <Text>{children}</Text>;

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: renderParagraph,
  },
};

const icons = {
  "team-as-a-service": "pe-7s-users",
  "frontend-development": "pe-7s-monitor",
  "mobile-app-development": "pe-7s-phone",
  "backend-development": "pe-7s-server",
};

const getIconClass = slug => icons[slug];

const Service = ({ title, slug, description }) => {
  const { json } = description;
  const components = documentToReactComponents(json, options);
  const iconClass = getIconClass(slug);
  const iconClassCustom = `${iconClass} text-custom`;
  return (
    <div className="col-lg-4 margin-t-20">
      <div className="services-box text-center hover-effect">
        <i className={iconClassCustom} />
        <h4 className="padding-t-15">{title}</h4>
        {components}
      </div>
    </div>
  );
};

export default Service;
