import React from "react";
import "./Step.css";

const icons = {
  "your-requirements": "pe-7s-pen",
  "our-offer": "pe-7s-like2",
  "from-draft-to-launch": "pe-7s-rocket",
};

const getIconClass = slug => icons[slug];

const Step = ({ title, slug, description }) => {
  const iconClass = getIconClass(slug);
  const iconClassCustom = `${iconClass} text-custom`;
  return (
    <div className="col-lg-4 plan-line">
      <div className="text-center process-box">
        <i className={iconClassCustom} />
        <h4 className="padding-t-15">{title}</h4>
        <p className="text-muted">{description}</p>
      </div>
    </div>
  );
};

export default Step;
