import React from "react";
// import { Link } from 'gatsby';
import { graphql } from "gatsby";
// import PropTypes from 'prop-types'

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/Hero";
import SectionTeam from "../components/SectionTeam";
import SectionProcess from "../components/SectionProcess";
// import SectionContact from "../components/SectionContact";
import SectionServices from "../components/SectionServices";
import SectionClients from "../components/SectionClients";
import SectionTechnologies from "../components/SectionTechnologies";
// import SectionImprint from "../components/SectionImprint";

const IndexPage = ({ data, pageContext }) => {
  const {
    title,
    hero,
    services,
    clients,
    technologies,
    // imprint,
    team,
    theProcess,
    // contact,
  } = data.page.edges[0].node;
  return (
    <Layout pageContext={pageContext}>
      <SEO title={`Ritzel`} keywords={[`ritzel`, `react native`, `react`]} />
      <Hero {...hero} />
      <SectionServices {...services} />
      <SectionProcess {...theProcess} />
      <SectionTeam {...team} />
      <SectionClients {...clients} />
      <SectionTechnologies {...technologies} />
      {/* Let's talk! */}
      {/* * Contact us and we can get started to draft, build and launch your product. * */}
      {/* <SectionContact {...contact} /> */}
      {/* <SectionImprint {...imprint} /> */}
    </Layout>
  );
};

export const indexQuery = graphql`
  query IndexQuery($locale: String!) {
    page: allContentfulPage(
      filter: { slug: { eq: "devthingsio" }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          id
          title
          node_locale
          hero {
            id
            title
            subtitle
            background {
              id
              file {
                url
                fileName
                contentType
              }
            }
          }
          services {
            id
            title
            subtitle
            services {
              id
              title
              slug
              description {
                id
                json
              }
            }
          }
          theProcess {
            id
            title
            description
            steps {
              id
              title
              description
              slug
            }
          }
          team {
            id
            title
            subtitle
            teammembers {
              id
              name
              description
              photo {
                id
                file {
                  url
                  fileName
                  contentType
                }
              }
            }
          }
          clients {
            id
            title
            subTitle
            slug
            clients {
              id
              name
              slug
              url
              logo {
                id
                file {
                  url
                  fileName
                  contentType
                }
              }
            }
          }
          technologies {
            id
            title
            subTitle
            slug
            technologies {
              id
              name
              slug
              url
              logo {
                id
                file {
                  url
                  fileName
                  contentType
                }
              }
            }
          }
          contact {
            id
            title
            subtitle
            slug
            labelName
            labelEmail
            labelSubject
            labelMessage
            labelSendMessage
          }
          imprint {
            id
            title
            slug
            text {
              id
              json
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
