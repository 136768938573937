import React from "react";
import Service from "../Service";
import utils from "../../utils";

const SectionServices = ({ title, subtitle, services }) => {
  const sortedServices = services.sort(utils.sortFromAtoZ);
  const servicesComponents = sortedServices.map(service => (
    <Service key={service.slug} {...service} />
  ));
  return (
    <section className="section pt-5" id="services">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <h1 className="section-title text-center">{title}</h1>
            <div className="section-title-border margin-t-20" />
            <p className="section-subtitle text-muted text-center padding-t-30 font-secondary">
              {subtitle}
            </p>
          </div>
        </div>
        <div className="row justify-content-center margin-t-30">
          {servicesComponents}
        </div>
      </div>
    </section>
  );
};

export default SectionServices;
