import React from "react";
import TeamMember from "../TeamMember";

const SectionTeam = ({ title, subtitle, teammembers }) => {
  const teamMembersComponents = teammembers.map(teammember => (
    <TeamMember key={teammember.id} {...teammember} />
  ));
  return (
    <section className="section pt-5" id="team">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <h1 className="section-title text-center">{title}</h1>
            <div className="section-title-border margin-t-20" />
            <p className="section-subtitle text-muted text-center padding-t-30 font-secondary">
              {subtitle}
            </p>
          </div>
        </div>
        <div className="row justify-content-center margin-t-50">
          {teamMembersComponents}
        </div>
      </div>
    </section>
  );
};

export default SectionTeam;
