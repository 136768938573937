import React from "react";

const Hero = ({ title, subtitle, background }) => {
  const { url } = background.file;
  return (
    <section
      className="section bg-home height-100vh"
      id="home"
      style={{
        backgroundImage: `url(${url})`,
      }}
    >
      <div className="bg-overlay" />
      <div className="display-table">
        <div className="display-table-cell">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 text-white text-center">
                <h1 className="home-title">{title}</h1>
                <p className="padding-t-15 home-desc">{subtitle}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
