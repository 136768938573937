import React from "react";
import Step from "../Step";

const SectionProcess = ({ title, subtitle, steps }) => {
  const stepsComponents = steps.map(step => <Step key={step.slug} {...step} />);
  return (
    <section className="section pt-5" id="process">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <h1 className="section-title text-center">{title}</h1>
            <div className="section-title-border margin-t-20" />
            <p className="section-subtitle text-muted text-center padding-t-30 font-secondary">
              {subtitle}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 text-center process-left-icon-1">
            <i className="pe-7s-angle-right" />
          </div>
          <div className="col-lg-6 text-center process-left-icon-2">
            <i className="pe-7s-angle-right" />
          </div>
        </div>
        <div className="row margin-t-50">{stepsComponents}</div>
      </div>
    </section>
  );
};

export default SectionProcess;
