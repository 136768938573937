import React, { useState, useEffect, useCallback, Fragment } from "react";
import { navigate } from "gatsby";
import "./NavBar.css";

interface LangButtonProps {
  label: string;
  active: boolean;
  onClick: () => void;
}

const LangButton: React.FC<LangButtonProps> = ({
  label,
  active,
  onClick,
}: LangButtonProps) => (
  <button
    type="button"
    onClick={onClick}
    className="nav-link"
    style={{
      textDecoration: active ? "underline" : "none",
    }}
  >
    {label}
  </button>
);

interface NavBarProps {
  siteTitle: string;
  pageContext: any;
}

export const NavBar: React.FC<NavBarProps> = ({
  siteTitle,
  pageContext,
}: NavBarProps) => {
  const [isSticky, setIsSticky] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    const scrollListener = () => {
      const scrolled = document.scrollingElement?.scrollTop ?? 0;
      if (scrolled > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
      return () => {
        window.removeEventListener("scroll", scrollListener);
      };
    };
    window.addEventListener("scroll", scrollListener);
  });

  const setCollapsedCallback = useCallback(() => setCollapsed(!collapsed), [
    collapsed,
  ]);

  const strings = {
    home: "Home",
    services: "Services",
    features: "Features",
    pricing: "Pricing",
    team: "Team",
    blog: "Blog",
    letsTalk: "Let's talk!",
    separator: " | ",
    emailTo: "florian@ritzel.ie",
    emailSub: "Enquiry regarding doing business",
    emailBody: "Hello Florian,",
  };

  const emailCallback = useCallback(
    () =>
      window.open(
        `mailto:${strings.emailTo}?&subject=${strings.emailSub}&body=${strings.emailBody}`,
        "_self"
      ),
    [strings.emailTo, strings.emailSub, strings.emailBody]
  );

  return (
    <div className={isSticky ? "is-sticky" : ""}>
      <nav className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark">
        <div className="container">
          <a className="navbar-brand logo text-uppercase" href="/">
            {siteTitle}
          </a>
          <button
            onClick={setCollapsedCallback}
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="mdi mdi-menu" />
          </button>
          <div
            className={
              collapsed
                ? "collapse navbar-collapse show collapse-navbar"
                : "collapse navbar-collapse show"
            }
            id="navbarCollapse"
          >
            <ul className="navbar-nav navbar-center" id="mySidenav">
              <li className="nav-item active">
                <a href="#home" className="nav-link">
                  {strings.home}
                </a>
              </li>
              <li className="nav-item">
                <a href="#services" className="nav-link">
                  {strings.services}
                </a>
              </li>
              <li className="nav-item">
                <a href="#features" className="nav-link">
                  {strings.features}
                </a>
              </li>
              <li className="nav-item">
                <a href="#pricing" className="nav-link">
                  {strings.pricing}
                </a>
              </li>
              <li className="nav-item">
                <a href="#team" className="nav-link">
                  {strings.team}
                </a>
              </li>
              <li className="nav-item">
                <a href="#blog" className="nav-link">
                  {strings.blog}
                </a>
              </li>
              {/* <li className="nav-item">
                <a href="#contact" className="nav-link">
                  Contact
                </a>
              </li> */}
              <li className="nav-item d-flex">
                {pageContext.languages.map(
                  (language: { locale: string }, index: number) => {
                    const { locale } = pageContext;
                    const handleSetLang = (lang: string) => {
                      const { originalPath } = pageContext;
                      const newPathname = `/${lang}${originalPath}`;

                      localStorage.setItem("language", lang);
                      navigate(newPathname);
                    };
                    const separator =
                      index > 0 ? (
                        <span className="language-separator">
                          {strings.separator}
                        </span>
                      ) : (
                        ""
                      );
                    const handleSetLangCallback = useCallback(
                      () => handleSetLang(language.locale),
                      [language.locale]
                    );
                    return (
                      <Fragment key={language.locale}>
                        {separator}
                        <LangButton
                          active={language.locale === locale}
                          label={language.locale}
                          onClick={handleSetLangCallback}
                        />
                      </Fragment>
                    );
                  }
                )}
              </li>
            </ul>
            <div className="nav-button ml-auto">
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <button
                    type="button"
                    className="btn btn-custom navbar-btn btn-rounded waves-effect waves-light"
                    onClick={emailCallback}
                  >
                    {strings.letsTalk}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
