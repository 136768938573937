import React from "react";
import Client from "../Client";
import utils from "../../utils";

const SectionClients = ({ title, subTitle, clients }) => {
  clients.sort(utils.sortFromAtoZ);
  return (
    <section className="section pt-5" id="clients">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <h1 className="section-title text-center">{title}</h1>
            <div className="section-title-border margin-t-20" />
            <p className="section-subtitle text-muted text-center padding-t-30 font-secondary">
              {subTitle}
            </p>
          </div>
        </div>
        <div className="row justify-content-center margin-t-30">
          {clients.map(client => (
            <Client key={client.slug} {...client} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SectionClients;
